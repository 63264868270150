<template>
  <!--  {{productData}}-->
  <div>
    <!-- <Head v-if="Product !== null"> -->
      <!-- <Title>{{ Product.productPageTitle === null ? $rt(Product.productName) : $rt(Product.productPageTitle) }}</Title> -->
      <!-- <Meta name="description" :content="$rt(Product.productName)" /> -->
    <!-- </Head> -->
  </div>
  <div v-if="isStory">
    <ProductStory :page="getStoryData" />
  </div>
  <div v-else class="products-container">
    <ProductBanner :item="banner" />
    <div class="product-outer-container">
      <ProductNav :page="productNav" ref="productNav" class="product-nav" />
      <div class="product-content-container">
        <div class="product-content">
          <ProductPerformance v-show="ProductPerf !== null" :productTitle="getHeader('performance')" />
          <ProductFeatures v-if="Features !== null" :productTitle="getHeader('features')" :items="Features" />
          <ProductModels v-if="Models.length > 0" :productTitle="getHeader('models')" :items="Models" />
          <ProductPlates v-if="Plates.length > 0" :productTitle="getHeader('plates')" :items="Plates" />
          <ProductResources v-show="Resources.length > 0" :productTitle="getHeader('resources')" :items="Resources"
            type="products" :attach="getProductNav" />
          <ProductRelated v-show="RelatedProducts.length > 0" :productTitle="getHeader('relatedProducts')"
            :item="RelatedProducts" />
          <ProductGallery v-bind="$attrs" v-show="Gallery.length > 0" id="gallery"
            :productTitle="getHeader('viewPhotoGallery')" :items="Gallery" />
          <ProductRecommended v-show="recommendedFor.length > 0" :productTitle="getHeader('recommendedFor')"
            :items="recommendedFor" />
          <div class="warranty-notes" v-html="$rt(terms.warrantyHead.mltText)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductStory from '~/components/products/ProductStory.vue';
import ProductNav from '~/components/products/ProductNav.vue';
import ProductBanner from '~/components/products/ProductBanner.vue';
import ProductFeatures from '~/components/products/ProductFeatures.vue';
import ProductModels from '~/components/products/ProductModels.vue';
import ProductPlates from '~/components/products/ProductPlates.vue';
import ProductRelated from '~/components/products/ProductRelated.vue';
import ProductGallery from '~/components/products/ProductGallery.vue';
import ProductRecommended from '~/components/products/ProductRecommended.vue';
import ProductResources from '~/components/products/ProductResources.vue';
import ProductPerformance from '~/components/products/ProductPerformance.vue';
import LocLink from '~/components/base/LocLink.vue';
import { usePerformanceStore } from '@/store/PerformanceStore';
import { useProductStore } from '~/store/ProductStore';
import ProductSolutions from '~/json/products.solutions.json';
import ProductCrops from '~/json/products.crops.json'
import productResources from '../../../json/languages/en/en.products.resources.json';
import Stories from '../../../json/languages/en/en.storymodules.json';
import { useI18n } from "vue-i18n";

export default {
  setup() {

  },
  data() {
    return {
      selectedNav: 0,
      lastWord: null,
      products: null,
      productData: null,
      perfData: null,
      currentStoryData: null,
      product: null,
      storyBannerTitle: null
    };
  },

  components: {
    ProductStory,
    ProductNav,
    ProductBanner,
    ProductFeatures,
    ProductModels,
    ProductPlates,
    ProductRelated,
    ProductGallery,
    ProductRecommended,
    ProductResources,
    ProductPerformance,
    LocLink
  },

  computed: {
    terms() {
      const terms = this.$tm('extraTerms');
      var warranty = terms.find((element) => {
        if (element.mlVar != 'NULL' && element.mlVar != null) {
          return this.$rt(element.mlVar) === 'productWarrantyHead';
        }
      });
      return {
        warrantyHead: warranty
      }
    },
    isStory() {
      const route = useRoute();
      const currRoute = route.path.split('/');
      return currRoute.pop() === 'story' ? true : false;
    },
    getStoryData() {
      const route = useRoute();
      var paths = route.path.split('/');
      paths.pop();
      var pfUrl = paths.pop();
      var curLocale = useI18n().locale.value;
      var currStoryData = null;
      let fams = this.$tm('productFamilies');
      const storyData = this.$tm('productStory');
      const values = Object.values(fams);
      const productFamily = values.find((element) => this.$rt(element.productFamilyURL) === pfUrl);

      if (curLocale === "en") {
        currStoryData = Stories.productStory.filter((element) => element.pfID_fk === productFamily.pfID);
        const bannerMod = currStoryData.findIndex((element) => element.modID_fk === 1);
        currStoryData[bannerMod].storyTitle = productFamily.productFamilyPageTitle !== null ? productFamily.productFamilyPageTitle : productFamily.productFamily;
      }
      else {
        currStoryData = storyData.filter((element) => element.pfID_fk === productFamily.pfID);
        const bannerMod = currStoryData.findIndex((element) => element.modID_fk === 1);
        currStoryData[bannerMod].storyTitle = productFamily.productFamilyPageTitle !== null ? productFamily.productFamilyPageTitle : productFamily.productFamily;
        //check mediaURLs
        currStoryData.forEach((story) => {
          if (story.mediaURL1 === null)//grab en version
          {
            var enStory = Stories.productStory.find((ps) => ps.pmID_fk === story.pmID_fk);
            if (enStory) {
              story.mediaURL1 = enStory.mediaURL1;
              story.mediaURL2 = enStory.mediaURL2;
              story.mediaURL3 = enStory.mediaURL3;
              if (!story.pmText1) {
                story.pmText1 = enStory.pmText1;
                story.pmText2 = enStory.pmText2;
                story.pmText3 = enStory.pmText3;
              }
            }
          }
        })
      }
      return currStoryData;
    },
    Plates() {
      var platesData = this.$tm('plates');
      const performanceStore = usePerformanceStore();
      const id = parseInt(this.Product.pID);
      const plates = platesData.filter(
        (element) => element.pID_fk === id
      );
      plates.forEach((element) => {
        element.perfData = performanceStore.getAllPerformanceData(element.pID_fk);
      })
      if (plates && plates.length > 0)
        return plates;
      else return [];
    },
    currentRoute() {
      const route = useRoute();
      return route.params.id;
    },
    ProductPerf() {
      const performanceStore = usePerformanceStore();
      return performanceStore.getPerformanceData(this.Product.pID);
    },
    Product() {
      let allProducts = this.$tm('products');
      //let hiddenProducts = allProducts.filter((prod) => prod.hideViewProductLink === 1);
      /* console.log('--------PRODUCTS WIHTOUT PAGE CONTENT: \n');

      hiddenProducts.forEach((element) => console.log(this.$rt(element.productURL)));
      console.log('--------END PRODUCTS WIHTOUT PAGE CONTENT: \n'); */
      //find product family from url
      const route = useRoute();
      if (route.params.id != 'story') {
        const route = useRoute();
        var paths = route.path.split('/');
        var prodUrl = paths.pop();
        //if there are models under the product, their product url is the same
        let product = allProducts.filter((prod) => (this.$rt(prod.productURL)).toLowerCase() === prodUrl.toLowerCase());
        if (product.length > 1) {
          //filter by isModel = 0
          product = product.filter((prod) => prod.isModel === 0)[0];
        }
        else
          product = product[0];

        if (!product) {//no product found....broken link?
          return null;
        }

        return product;
      }
    },
    ProductFamily() {
      const route = useRoute();
      const currentPath = route.path.split('/');
      var pfUrl = currentPath[currentPath.length - 2];//to look up product family
      let fams = this.$tm('productFamilies');
      var entryValues = Object.values(fams);
      var family = entryValues.find((pf) => this.$rt(pf.productFamilyURL) === pfUrl);
      return family;
    },
    banner() {
      const banner = {
        bannerPath: '/products/' + this.$rt(this.ProductFamily.productFamilyURL),
        bannerLink: (this.ProductFamily.productFamily) ? this.ProductFamily.productFamily : "",
        title: this.Product.productName,
        paragraphs: [
          this.Product.productDescription ? this.Product.productDescription : ''
        ],
        resourceUri: this.Product.productURL,
      };

      return banner;
    },
    Gallery() {
      const productStore = useProductStore();
      let gal = productStore.getGalleryData(this.Product.pID);
      if (gal)
        return gal;
      else return [];
    },
    Features() {
      if (this.Product.productFeatures)
        return this.Product.productFeatures;
      else return null;
    },
    Models() {
      const productStore = useProductStore();
      var models = productStore.getModelData(this.Product.pID, this.$tm('products'));
      var tmp = models.filter((item) => item.hideViewProductLink === 0);
      models = tmp;
      if (models && models.length > 0)
        return models;
      else return [];
    },
    RelatedProducts() {
      const productStore = useProductStore();
      let relProducts = productStore.getProductRelatedData(this.Product, this.$tm('products'), this.$tm('productFamilies'));
      if (relProducts && relProducts.length > 0)
        return relProducts;
      else return [];
    },
    Resources() {
      var curLocale = useI18n().locale.value;
      var tmp = this.$tm('productResources');
      var resources = [];
      //need to get english resources first.
      var enResources = productResources.productResources.filter(
        (element) => parseInt(element.cspID_fk) === parseInt(this.Product.pID)
      )
      if (curLocale !== 'en') {
        resources = tmp.filter(
          (element) => (parseInt(element.cspID_fk) === parseInt(this.Product.pID)) && (element.filename !== null || element.videoURL !== null)
        );
      }
      if (curLocale === 'en')
        resources = enResources;
      else {
        enResources.forEach((en) => {
          var found = resources.find((re) => re.libID_fk === en.libID_fk);
          if (!found) {
            resources.push(en);
          }
        })
      }

      return resources;
    },
    Terms() {
      let terms = this.$tm('extraTerms');
      return terms;
    },
    storyData() {
      return this.currentStoryData;
    },
    productNav() {

      const productStore = useProductStore();
      const performanceStore = usePerformanceStore();
      const mltVar = [];
      // performance
      if (performanceStore.performanceData !== undefined) {
        mltVar.push({ 'term': 'performance', 'order': 0 });
      }

      if (this.Product.productFeatures) {
        mltVar.push({ 'term': 'features', 'order': 1 });
      }
      // models
      if (this.Models.length > 0) {
        mltVar.push({ 'term': 'models', 'order': 2 });
      }
      // plates
      if (this.Plates.length > 0) {
        mltVar.push({ 'term': 'plates', 'order': 3 });
      }
      // resources
      if (this.Resources.length > 0) {
        mltVar.push({ 'term': 'resources', 'order': 4 });
      }
      // related products
      if (this.RelatedProducts.length > 0) {
        mltVar.push({ 'term': 'relatedProducts', 'order': 5 });
      }
      // gallery
      if (this.Gallery.length > 0) {
        mltVar.push({ 'term': 'viewPhotoGallery', 'order': 6 });
      }
      // recommended for
      if (this.recommendedFor.length > 0) {
        mltVar.push({ 'term': 'recommendedFor', 'order': 7 });
      }
      var termHeaders = [];
      mltVar.forEach((item) => {
        var termFound = this.Terms.find((term) => { if (term.mlVar != 'NULL' && term.mlVar != null) { return this.$rt(term.mlVar) === item.term; } });
        if (termFound) {
          termFound.order = item.order;
          termHeaders.push(termFound);
        }
      });

      const productNav = termHeaders.sort((a, b) => a.order - b.order);
      productStore.productNav = productNav;

      return productNav;
    },
    recommendedFor() {
      var allSolutions = this.$tm('solutions');
      var allCrops = this.$tm('crops');
      //gather crops for product
      var prodCrops = ProductCrops.productCrops.filter((entry) => entry.pID_fk == this.Product.pID)
        .map((each) => {
          //find crop and map it
          var tCrop = allCrops.find((crop) => crop.cropID_fk === each.cropID_fk);
          return {
            name: tCrop.crop,
            url: '/crops/' + this.$rt(tCrop.cropURL),
          }
        });
      //gather solution for product
      var prodSolutions = ProductSolutions.productSolutions.filter((entry) => entry.pID_fk == this.Product.pID)
        .map((each) => {
          //find crop and map it
          var tSolution = allSolutions.find((sol) => sol.solID_fk === each.solID_fk);
          return {
            name: tSolution.solution,
            url:
              '/solutions/' +
              this.$rt(tSolution.solutionGroupURL) +
              '/' +
              this.$rt(tSolution.solutionURL),
          }
        });

      if (prodCrops.length > 0 || prodSolutions.length > 0)
        return prodCrops.concat(prodSolutions);
      else return [];
    }
  },
  methods: {

    getProductNav() {
      if (this.Product) {
        console.log(this.$refs.productNav, ' prod nav');
        return this.$refs.productNav;
      }
      else return {};
    },

    getHeader(mlVar) {

      const header = this.Terms.filter((element) => this.$rt(element.mlVar) === mlVar);

      let mlKeys = [
        //   "ID_fk",
        "Var",
        //   "ID",
        "tLastUpdated",
        "tLocale",
        "tText"
      ];

      for (let a = 0; a < mlKeys.length; a++) {
        header[0]['ml' + mlKeys[a]] = this.$rt(header[0]['ml' + mlKeys[a]]);
      }

      return header[0];

    },
    /* getPlateImage(plate) {
       var imageUrl = '/images/products/rotator-' + plate.plateSKU + '-plate.webp';
       return imageUrl;
     },*/
  },
};
</script>

<style>
/* DO NOT DELETE - this is a style for all ProductXXXXX components */
.product-title {
  border-bottom: 1px solid black;
  font-size: 15px;
  letter-spacing: 0.03em;
  padding-bottom: 20px;
  margin-top: 45px;
  margin-bottom: 25px;
}

.first-product-data {
  margin-top: 0;
}
</style>

<style scoped>
.product-outer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto 0;
}

.product-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 2.5;
  width: 100%;
}

.product-content {
  width: 100%;
  max-width: 800px;
  padding-top: 40px;
}

hr {
  width: 100%;
}

.warranty-notes {
  font-size: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
  align-self: flex-start;
}

.product-models {
  width: 100%;
}

.product-nav {
  margin-right: 50px;
}

@media only screen and (max-width: 1100px) {

  .product-outer-container {
    padding: 0px 50px 50px;

  }
}

@media only screen and (max-width: 1100px) {

  .product-outer-container {
    padding: 0px 20px 20px;

  }
}
</style>