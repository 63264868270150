<template>
  <div v-show="getImgData != null">
    <h1>{{ item }}</h1>
    <div v-for="img in getImages">
      <img :src="img" />
    </div>
    <label v-for="alt in getAlt">{{ alt }}</label>
  </div>
</template>

<script>
export default {
  mounted () {
    this.imgData = this.$props.items;
  },
  data () {
    return {
      imgData: null
    };
  },
  props: [
    'items'
  ],
  computed: {
    getImgData () {
      return this.imgData;
    },
    getImages () {
      return this.imgData.resourceUri;
    },
    getAlt () {
      return this.imgData.alt;
    }
  }
};
</script>

<style scoped></style>