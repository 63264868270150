<template>
  <div v-if="performanceData" :id="productTitle.mlVar">
    <h4 class="product-title first-product-data" :id="productTitle.mlVar">{{ productTitle.mltText.toUpperCase() }}</h4>
    <div class="perfData">
      <p class="perfIcon perfFlow" v-if="performanceData.flowLowUS != null && performanceData.flowLowUS != 'NULL'">Flow:
        <b>{{
          performanceData.flowLowUS }}-{{ performanceData.flowHighUS }} gpm
        </b>({{ performanceData.flowLowM }}-{{ performanceData.flowHighM }} m3/hr)
      </p>
      <p class="perfIcon perfPressure"
        v-if="performanceData.pressureLowUS != null && performanceData.pressureLowUS != 'NULL'">
        Pressure: <b>{{
          performanceData.pressureLowUS }}-{{
            performanceData.pressureHighUS }} psi </b>({{ performanceData.pressureLowM }}-{{
            performanceData.pressureHighM }} bar)</p>
      <p class="perfIcon perfRadius"
        v-if="performanceData.radiusLowUS != null && performanceData.radiusLowUS != 'NULL'">Radius: <b>{{
          performanceData.radiusLowUS }}-{{
            performanceData.radiusHighUS }} ft </b>({{ performanceData.radiusLowM }}-{{
            performanceData.radiusHighM }} m)</p>
      <p class="perfIcon perfDiameter"
        v-if="performanceData.diameterLowUS != null && performanceData.diameterLowUS != 'NULL'">
        Diameter: <b>{{
          performanceData.diameterLowUS }}-{{ performanceData.diameterHighUS }} ft</b>
        ({{ performanceData.diameterLowM }}-{{ performanceData.diameterHighM }} m)</p>
      <p class="perfIcon perfNozzlesize"
        v-if="performanceData.nozzleSize != null && performanceData.nozzleSize != 'NULL'">Nozzle
        Size: <b>{{
          performanceData.nozzleSize }}</b></p>
      <p v-if="performanceData.trajectoryData != null && performanceData.trajectoryData != 'NULL'"
        class="perfIcon perfTrajectory">
        Trajectory:
        <b>{{ performanceData.trajectoryData }}</b>
      </p>
    </div>
  </div>

</template>

<script>
import { usePerformanceStore } from '@/store/PerformanceStore';

export default {
  data() {
    return {
      specs: null,
    };
  },
  props: [
    'items',
    'productTitle'
  ],
  mounted() {
    this.specs = this.$props.items;
  },
  computed: {
    performanceData() {
      const perfData = usePerformanceStore().performanceData;
      if (perfData) { return perfData; }
      else { return null; }
    },
  }
};
</script>
<style scoped>




@media only screen and (max-width: 650px) {



}
</style>
