<template>
  <div>
    <h4 class="product-title" :id="productTitle.mlVar">{{ productTitle.mltText.toUpperCase() }}</h4>
    <div class="product-plates-container" v-if="getPlates.length > 0">
      <section class="product-plates-selector">
        <div class="product-plates-selector-box" v-for="item, index in getPlates" :key="index"
          :style="getSelectedStyles(item)" @click="handlePlate(item, index)" :id="'plateIndex' + index">
          <div class="product-plates-selector-img-box">
            <div class="product-plates-selector-img" :style="'background-image: url(' + plateUri(item) + ')'"></div>
          </div>
          <h3 class="product-plates-selector-text">{{ $rt(item.plateName) }}</h3>
        </div>
      </section>
      <section class="product-plates-pag">
        <div>
          <div class="product-plates-prev" @click="handlePrevPlate()" v-if="getIndex > 0">
            <p class="product-plates-pag-text">PREVIOUS</p>
            <svg class="page-arrow" style="rotate: 90deg;" xmlns="http://www.w3.org/2000/svg" width="14.021"
              height="8.424">
              <path
                d="M.293.293a.999.999 0 011.414 0L7.01 5.595 12.314.293a1 1 0 011.32-.083l.094.083a.999.999 0 010 1.414L7.01 8.424.293 1.707A1 1 0 01.21.387L.293.293z"
                fill="#3B3B3B"></path>
            </svg>
          </div>
        </div>
        <div>
          <div class="product-plates-next" @click="handleNextPlate()" v-if="getIndex < getPlatesLength - 1">
            <p class="product-plates-pag-text">NEXT</p>
            <svg class="page-arrow" style="rotate: -90deg;" xmlns="http://www.w3.org/2000/svg" width="14.021"
              height="8.424">
              <path
                d="M.293.293a.999.999 0 011.414 0L7.01 5.595 12.314.293a1 1 0 011.32-.083l.094.083a.999.999 0 010 1.414L7.01 8.424.293 1.707A1 1 0 01.21.387L.293.293z"
                fill="#3B3B3B"></path>
            </svg>
          </div>
        </div>
      </section>
      <section>
        <div v-for="item, index in getPlates" :key="index">
          <div class="product-plates-content" v-if="item.isShow">
            <div class="product-plates-data">
              <h1 class="product-plates-name">{{ $rt(item.plateName) }}</h1>
              <h3 class="product-plates-subhead" v-if="item.plateBenefit">{{ $rt(item.plateBenefit) }}</h3>
              <div class="product-plates-summary" v-if="item.plateFeatures" v-html="$rt(item.plateFeatures)"></div>
              <BasePerformance :performanceData="item.perfData" />
              <div class="product-plates-page-box">
                <nuxt-link v-for="pg, index in item.pages" :key="index" :to="pg.url" class="product-plates-page-link">{{
                  $rt(pg.name) }}</nuxt-link>
              </div>
            </div>
            <div class="product-plates-image-box">
              <img class="product-plates-img" :src="plateSprUri(item)">
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import BasePerformance from '../base/BasePerformance.vue';
export default {
  data() {
    return {
      index: null,
    };
  },
  props: [
    'items',
    'productTitle'
  ],
  components: {
    BasePerformance
  },
  mounted() {

  },
  computed: {
    getPlates() {
      if (this.items) {
        if (this.items[0].hasOwnProperty('isShow')) {
          return this.items;
        }
        else { //set viewability
          this.items.forEach((item, index) => {
            if (index === 0) {
              item.isShow = true;
            } else {
              item.isShow = false;
            }
          });
          this.index = 0;
          return this.items;
        }
      }
      return [];
    },
    getIndex() {
      return this.index;
    },
    getPlatesLength() {
      if (this.items)
        return this.items.length;
      return 0;
    },
    productURL() {
      const router = useRouter();
      return router.currentRoute.value.params.id;//this is the selected product
    }
  },
  methods: {
    plateUri(plate) {
      return '/images/products/' + this.$rt(this.productURL) + '-' + this.$rt(plate.plateSKU) + '-plate.webp';
    },
    plateSprUri(plate) {
      return '/images/products/' + this.$rt(this.productURL) + '-' + this.$rt(plate.plateSKU) + '-sprinkler.webp';
    },
    getSelectedStyles(item) {
      if (item.isShow) {
        return {
          'color': '#fff',
          'background-color': '#3B3B3B',
          'border': '1px solid transparent'
        };
      } else {
        return {
          'color': 'black',
          'background-color': '#FFFFFF',
          'border': '1px solid #F3F3F3'
        };
      }
    },
    resetPlateSelection() {
      this.items.forEach((item) => {
        item.isShow = false;
      });
    },
    handlePlate(item, num) {
      this.resetPlateSelection();
      item.isShow = true;
      this.index = num;
    },
    handleNextPlate() {
      const currentIndex = this.index + 1;
      const numItems = this.items.length - 1;
      if (currentIndex <= numItems) {
        this.resetPlateSelection();
        this.scrollToElement(currentIndex);
        this.items[currentIndex].isShow = true;
        this.index = currentIndex;
      }
    },
    handlePrevPlate() {
      const currentIndex = this.index - 1;
      if (currentIndex >= 0) {
        this.resetPlateSelection();
        this.scrollToElement(currentIndex);
        this.items[currentIndex].isShow = true;
        this.index = currentIndex;
      }
    },
    scrollToElement(index) {
      const element = document.getElementById('plateIndex' + index);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }
    }
  }
};
</script>
<style scoped>
.product-plates-content {
  display: flex;
  flex-direction: row;
}

.product-plates-title {
  border-bottom: 1px solid black;
  margin: 0;
  padding-bottom: 0.75rem;
}

.product-plates-selector {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  max-width: 900px;
  overflow-x: scroll;
  padding-bottom: 15px;
}

.product-plates-pag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;
  user-select: none;
}

.product-plates-pag-text {
  color: #3B3B3B;
  font-weight: 700;
  font-size: 12px;
  padding: 10px;
  text-transform: uppercase;
}

.product-plates-selector-box {
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-content: center;
}

.product-plates-selector-img-box {
  background-color: #fff;
  min-width: 145px;
  height: 110px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}

.product-plates-selector-img {
  width: calc(100% - 5px);
  height: 90px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}

.product-plates-selector-text {
  align-self: center;
  justify-self: center;
  font-size: 14px;
  padding: 10px;
}

.product-plates-icon-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.product-plates-page-link {
  border: 1px solid #D3CDC2;
  border-radius: 2px;
  margin-right: 3px;
  margin-bottom: 3px;
  color: #222222;
  font-size: 13px;
  text-decoration: none;
  padding: .75em 1em;
  transition: all .125s;
  display: block;
}

.product-plates-page-box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  width: 80%;
}

.product-plates-performance {
  padding-bottom: 50px;
}

.product-plates-defs,
.product-plates-summary {
  padding-bottom: 1rem;
}

.product-plates-next {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.product-plates-prev {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
}

.product-plates-name {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-plates-subhead {
  font-size: 17px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 10px;
}

.product-plates-icon {
  width: 14px;
  height: 14px;
}

.pp-p-bold {
  font-weight: bold;
}

.product-plates-data {
  flex: 2;
  margin-right: 20px;
}

.page-arrow {
  width: 14px;
  height: 14px;
  transform: scale(.8);
}

.product-plates-img {
  max-width: 200px;
}

.product-plates-image-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-plate-perf {
  display: flex;
  align-items: center;
}
</style>
