<template>
  <h4 class="product-title">{{ productTitle.mltText.toUpperCase() }}</h4>
  <div class="models-holder" v-if="getModels.length > 0" :id="productTitle.mlVar">

    <div class="product-models-outer-box" v-for="item, index in getModels" :key="index"
      @mouseover="handleHover(index)" @mouseleave="handleLeave(index)">

      <div class="product-models-img-box">
        <img class="product-models-img" :src="'/images/products/' +
          $rt(item.productURL) +
          '-hero.webp'">
      </div>

      <div class="product-models-info">

        <div class="product-models-info">
          <div class="product-models-text-box">
            <div class="product-models-head" v-html="$rt(item.productName)"></div>
            <div class="product-models-summary" v-html="$rt(item.productSummary)"></div>
          </div>
          <nuxt-link :to="$rt(item.productURL)" class="product-models-box" v-show="item.hideViewProductLink === 0">
            <div class="product-models-icon-box">
              <p class="product-models-view-product">View Product</p>
              <BaseArrow :ref="'arrow' + index" color="rgb(147, 147, 147)" hoverColor="#FFA500" />
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { usePillarStore } from '~/store/PillarStore';
import BaseArrow from '~/components/base/BaseArrow.vue';
export default {
  setup() {
    const pillarStore = usePillarStore();

    return {
      pillarStore, 
    };
  },
  components: {
    BaseArrow
  },
  props: [
    'items',
    'productTitle'
  ],
  
  data() {
    return {
      productNum: null,
    };
  },
  computed: {
    resourceUri() {
      return '/images/products/' +
        item.productURL +
        '-hero.webp';
    },
    getModels() {
      if (this.items) {
        return this.items;
      } else return [];
    },
    getProductData() {
      return this.pillarStore.productData;
    },
  },
  methods: {
    handleHover(index) {
      this.$refs[('arrow' + index)][0].handleOver();
    },
    handleLeave(index) {
      this.$refs[('arrow' + index)][0].handleOut();
    },
  }
};
</script>
<style scoped>
.models-holder {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-models-title {
  border-bottom: 1px solid black;
  margin: 0;
  padding-bottom: 0.75rem;
}

.product-models-box {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 100%;
}

.product-models-img-box {

  flex-shrink: 0;
  padding: 10px;
  min-width: 200px;
  max-width: 200px;
}

.product-models-img {
  margin: 0 auto;
  max-height: 180px;
  max-width: 100%;
}

.product-models-text-box {
  padding: 30px 0px 0 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-models-outer-box {
  display: flex;
  border: 1px solid #e7e7e7;
  padding: 5px;


}

.product-models-icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  border-top: 1px solid #E0E0E0;
  padding: 1em 0;
  font-size: 1rem;
  text-decoration: none;
  flex: 1;

}

.product-models-head {
  font-weight: 700;
  margin-bottom: 1em;
  font-size: 20px;
}

.product-models-view-product {
  font-weight: bold;
}

.product-models-summary {
  margin-bottom: 20px;
}


.product-models-info {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

@media only screen and (max-width: 700px) {



  .models-holder {
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
  }

  .product-models-outer-box {
    flex-direction: column;
    min-width: 275px;
    justify-content: space-between;
  }

  .product-models-box {
    flex-direction: column;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .product-models-icon-box {
    height: 40px;
  }
}
</style>
