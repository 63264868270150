<template>
  <div class="product-story" v-if="storyData">

    <div class="story-banner-image-container" :style="'background-image:' + 'url(' + banner.resourceUri + ')'">
      <div class="story-banner-image-box">
        <div class="story-banner-title">{{ $rt(banner.storyTitle) }}</div>
      </div>
    </div>

    <div class="story-details" v-for="(item, index) in storyData" :key="index">
      <div class="story-centered" v-if="item.modID_fk === 2">
      <h1 class="sc-headline">{{ $rt(item.pmHeadline1) }}</h1>
      <div class="sc-text" v-html="$rt(item.pmText1)"></div>
    </div>

    <StoryApplication v-if="item.modID_fk === 3" :items="item" />
  

    <div v-if="item.modID_fk === 4" class="story-sidebyside sbsright" id="story-details">
      <div class="sbs-copy">
        <div v-if="item.pmHeadline1 !== null" class="story-head" v-html="$rt(item.pmHeadline1)"></div>
        <div class="sbs-text" v-html="$rt(item.pmText1)"></div>
      </div>
      <div v-if="$rt(item.optPhotoOrVideo) === 'video'" class="sbs-media">
        <iframe :src="getVideoUrl(item.mediaURL1)" width="100%" height="100%"></iframe>
      </div>
      <div v-if="$rt(item.optPhotoOrVideo) === 'photo'" class="sbs-media">
        <img :src="getResourcePhoto(item.mediaURL1)" />
      </div>
    </div>

    <div v-if="item.modID_fk === 5" class="story-fullwidth" id="story-img-detail">
      <h1 v-if="item.pmHeadline1 !== null && item.pmHeadline1 != null" class="story-head" id="story-image-header">{{
        $rt(item.pmHeadline1) }}</h1>
      <img :src="getImg(item.mediaURL1)" class="sfw-img" />
      <p v-if="item.optCaption !== null" class="sfw-caption">{{ $rt(item.optCaption) }}</p>
      <div v-if="item.pmText1 !== null" class="sfw-text" v-html="$rt(item.pmText1)"></div>
    </div>

    </div>
    <div class="ps-spacer"></div>
    <ContentBanner v-if="productMade != null" :items="this.productMade" />
    <div v-if="storyProducts">
      <BaseRecommendations :items="storyProducts" />
    </div>
  </div>

</template>
<script>


import ContentBanner from '~/components/main/ContentBanner.vue';
import BaseBanner from '~/components/base/BaseBanner.vue';
import StoryApplication from '~/components/story/StoryApplication.vue';
import BaseDetail from '~/components/base/BaseDetail.vue';
import StoryBannerSummary from '~/components/story/StoryBannerSummary.vue';
import BaseRecommendations from '~/components/base/BaseRecommendations.vue';
import BaseImage from '../BaseImage.vue';
import BaseDetailList from '../base/BaseDetailList.vue';

export default {
  components: {
    ContentBanner,
    BaseBanner,
    StoryApplication,
    BaseDetail,
    StoryBannerSummary,
    BaseRecommendations,
    BaseImage,
    BaseDetailList,
  },
  props: ['page'],
  data() {
    return {
      productMade: null,
      productFamilies: null,
      productSpecs: null
    };
  },
  mounted() {
    this.productMade = (this.$tm('home'))[2];
    this.productFamilies = this.$tm('productFamilies');
    this.productSpecs = this.$tm('products');
  },
  computed: {
    storyData() {
      if (this.$props.page) {
      this.$props.page.sort((a, b) => a.pmSortorder - b.pmSortorder);
      return this.$props.page;
      }
    },
    banner() {
      if (this.$props.page) {
        var bannerData = this.$props.page.find((element) => element.modID_fk === 1);
        let split = (this.$rt(bannerData.mediaURL1)).split('.');
        split.pop();
        let name = split.join('.');
        bannerData.resourceUri = '/images/productfamilies/' + name + '.webp';
        if (bannerData.storyTitle === null) {
          bannerData.storyTitle = '';
        };
        return bannerData;
      }
    },
    storyProducts() {
      var terms = this.$tm('extraTerms');
      const route = useRoute();
      const currentPath = route.path;
      const lastWord = currentPath.split('/');
      var pfUrl = lastWord[lastWord.length - 2];
      if (terms === null || this.productFamilies === null || this.productSpecs === null) { return null; }
      const currentStoryPF = (this.productFamilies.find((element) => this.$rt(element.productFamilyURL) === pfUrl));
      const currentStoryProds = this.productSpecs.filter((element) => element.pfID_fk === currentStoryPF.pfID);
      const currentStoryProducts = currentStoryProds.filter((element) => element.isModel === 0);
      const productTerm = terms.find((element) => { if (element.mlVar !== null) {
        return this.$rt(element.mlVar) === 'products'; 
      }
      })
      const currProducts = {
        title: productTerm.mltText,
        items: currentStoryProducts
      };
      return currProducts;
    },

  },
  methods: {
    getResourcePhoto(img) {
      let split = this.$rt(img).split('.');
      split.pop();
      let name = split.join('.');
      return '/images/productfamilies/' + name + '.webp';
    },
    getImg(img) {
      let split = (this.$rt(img)).split('.');
      split.pop();
      let name = split.join('.');
      return '/images/productfamilies/' + name + '.webp';
    },
    getVideoUrl(item) {
      //if (item) {
        const video = this.$rt(item);
        const splitVideo = video.split('/');
        const videoId = splitVideo[3];
        const videoURL = 'https://youtube.com/embed/' + videoId;
        return videoURL;
      //}
      //else return '';
    }
  }
};
</script>

<style scoped>
.sfw-img {
  width: 100%;
}

#story-details,
#story-img-detail {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

#story-image-header {
  text-align: center;
}

.product-content-nav:hover .product-title-chevron {
  fill: black;
}

hr {
  width: 100%;
}

.story-detail-holder {
  width: 90%;
  max-width: 1200px;
}

.story-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.story-banner-image-container {
  display: flex;
  flex-direction: column;
  height: 500px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.story-banner-title {
  color: white;
  font-size: 40px;
}

.story-banner-image-box {

  color: #fff;
  padding: 7% 20px;
  max-width: 900px;
  font-size: 1.45rem;
  margin: 0 auto;
}

.sbs-media {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 330px;
}

.sbs-media img { max-width: 100%; }

.story-sidebyside.sbsright {
  flex-direction: row-reverse;

}

.sbs-copy {
  width: 48%;
}

.sbs-text { font-size: 2rem; }


@media all and (max-width: 900px) {
  .sbs-text {
    font-size: 1.8rem;
  }
}

@media all and (max-width: 750px) {
  .sbs-media {
    width: 100%;
    max-width: 430px;
    margin: 0 auto 20px;
    height: 250px;
  }
  .story-sidebyside { flex-direction: column; }
  .story-sidebyside.sbsright  { flex-direction: column-reverse; }

  .sbs-copy { width: 100%; margin: 0 auto;}
}


.ps-spacer {

  height: 60px;
}

.product-story {
  display: flex;
  flex-direction: column;
}
</style>
