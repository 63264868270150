<template>
  <div>
    <h4 class="product-title" :id="productTitle.mlVar">{{ productTitle.mltText.toUpperCase() }}</h4>
    <div class="product-recommended-box">
      <LocLink class="product-recommended-link" :to="$rt(item.url)" v-for="(item, index) in items" :key="index">{{
        $rt(item.name)
      }}</LocLink>
    </div>
  </div>
</template>

<script>
import LocLink from '../base/LocLink.vue';
export default {
  props: ['items', 'productTitle'],
  components: { LocLink },
};
</script>

<style scoped>
.product-recommended-box {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 5px;
}

.product-recommended-link {
  color: black;
  text-transform: none;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  padding: 0.5em 10px;
  background: #d3cdc2;
  transition: all 0.125s;
}

.product-recommended-link:hover {
  background-color: #0089d0;
  color: #ffffff;
}

.product-recommended {
  padding-bottom: 1rem;
}
</style>
