<template>
  <div v-if="getItems" class="story-app-container">
    <h1 v-if="getItems.pmHeadline4 != null" class="story-app-title">{{ $rt(getItems.pmHeadline4) }}</h1>
    <div class="story-app-card-holder">
      <div class="story-app-card" v-for="item, index in getApp">
        <img class="story-app-img" :src="item.resourceUri">
        <div class="story-app-content-group">
          <div class="story-app-card-title" v-html="$rt(item.header)"></div>
          <div class="story-app-card-content" v-html="$rt(item.paragraphs)"></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      resources: null,
      // images: null
    };
  },
  props: [
    'items'
  ],
  mounted () {
    this.resources = this.$props.items;
    // this.images = [this.$rt(this.resources.mediaURL1), this.$rt(this.resources.mediaURL2), this.$rt(this.resources.mediaURL3)];
  },
  unmounted() {
    this.resources = null;
  },
  computed: {
    getItems () {
      return this.resources;
    },
    getApp () {
      // console.log(this.images)
      // this.images.forEach((element, index) => {
      //   index = index + 1;
      //   let split = element.split('.');
      //   split.pop();
      //   let name = split.join('.');
      //   const img = 'mediaURL' + index;
      //   this.resources[img] = name;
      // });
      const apps = [
        {
          resourceUri: '/images/productfamilies/' + this.$rt(this.resources.mediaURL1),
          header: this.resources.pmHeadline1,
          paragraphs: this.resources.pmText1
        },
        {
          resourceUri: '/images/productfamilies/' + this.$rt(this.resources.mediaURL2),
          header: this.resources.pmHeadline2,
          paragraphs: this.resources.pmText2
        },
        {
          resourceUri: '/images/productfamilies/' + this.$rt(this.resources.mediaURL3),
          header: this.resources.pmHeadline3,
          paragraphs: this.resources.pmText3
        }
      ];
      return apps;
    },
  }
};
</script>

<style scoped>
.story-app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.story-app-card-holder {
  display: flex;
  gap: 40px;
}

.story-app-card-title {
  border-bottom: 1px solid black;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
}

.story-app-card-content {
  padding: 0 1rem;
}

.story-app-card-text {
  font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
}

.story-app-img {
  width: 100%;
  object-fit: cover;
}

.story-app-title {
  font-size: 38px;
  margin-top: 50px;
  margin-bottom: 25px;
}

.story-app-text {
  font-size: 18px;
  width: 78%;
  text-align: center;
  line-height: 1.8;

}

.story-app-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {

  .story-app-card-holder {
    flex-direction: column;
    gap: 40px;
  }

  .story-app-card {
    flex-direction: row;
  }

  .story-app-card-title {
    text-align: left;
    margin-left: 25px;
  }

  .story-app-card-text {}

  .story-app-img {
    width: 50%;

  }



}

@media only screen and (max-width: 620px) {

  .story-app-card-holder {
    flex-direction: column;
    gap: 40px;
  }

  .story-app-card {
    flex-direction: column;
  }

  .story-app-card-title {
    text-align: center;
    margin-left: 0px;
  }

  .story-app-img {
    width: 100%;
  }

}
</style>