<template>
  <div class="product-gallery-holder">
    <div class="product-gallery" v-if="getIsBlur === false">
      <h4 class="product-title" v-bind="$attrs" :id="productTitle.mlVar">{{ productTitle.mltText.toUpperCase() }}</h4>
      <div class="product-gallery-box">
        <div class="product-gallery-img-box" v-for="img, index in getGalleryData" :key="index"
          @click="this.handleImgFullscreen(img, index)">
          <img class="product-gallery-img" :src="this.getImagePath(img.pgFilename)">
        </div>
      </div>
    </div>
    <div v-if="getIsBlur" class="product-gallery-blur">

      <div class="product-gallery-header">
        <div class="pgh-left">
          {{ getGalleryCount }}
        </div>
        <div class="pgh-right">
          <div class="product-gallery-exit" @click="this.handleExitMode">
            <img :src="this.Close">
          </div>
        </div>
      </div>

      <div class="product-gallery-nav">

        <div :class="showHide('left')" class="gallery-arrow arrow-right" @click="this.handleNextLeftImg">
          <img class="product-gallery-right-arrow arrow-size" :src="this.Arrow">
        </div>

        <!--      <div class="product-gallery-fullscreen">-->
        <div class="product-gallery-main-img-box">
          <img class="product-gallery-main-img" :src="getSelectedImg">
        </div>
        <!--      </div>-->

        <div :class="showHide('right')" class="gallery-arrow arrow-left" @click="this.handleNextRightImg">
          <img class="product-gallery-left-arrow arrow-size" :src="this.Arrow">
        </div>

      </div>

      <div class="product-gallery-footer">
        <div class="product-gallery-icon-box">
          <div class="product-gallery-img-icon-card" v-for="img, index in getGalleryData" :key="index"
            :id="'carousel' + index" @click="handleImageCarousel(img, index)">
            <img class="product-gallery-img-icon" :src="getImagePath(img.pgFilename)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Arrow from '/svg/arrow-orange.svg?url';
import Close from '/svg/close.svg?url';
import gsap from 'gsap';
export default {
  setup() {
    return {
      Arrow,
      Close
    };
  },
  data() {
    return {
      galleryData: null,
      isBlur: false,
      selectedImg: null,
      currIndex: 0,
    };
  },
  mounted() {

    this.galleryData = this.$props.items;
    window.addEventListener('keyup', this.handleKey);

  },
  unmounted() {
    window.removeEventListener('keyup', this.handleKey);
  },
  props: [
    'items',
    'productTitle'
  ],
  computed: {
    getGalleryCount() {
      return (this.currIndex + 1) + ' / ' + this.galleryData.length;
    },
    getGalleryData() {
      return this.galleryData;
    },
    getIsBlur() {
      return this.isBlur;
    },
    getSelectedImg() {

      if(!this.selectedImg) {
        return
      }

      const fileName = this.selectedImg.pgFilename;
      return '/images/products/' + fileName.replace(/\.[^/.]+$/, '') + '.webp';
    },
    getCurrIndex() {
      return this.currIndex;
    },
  },
  methods: {

    handleKey (e) {

      switch(e.code) {

        case "ArrowRight":
          if (this.getCurrIndex+1 < this.getGalleryData.length) {
            this.handleNextRightImg();
          }
          break;
        case "ArrowLeft":
          if (this.getCurrIndex-1 > -1) {
            this.handleNextLeftImg();
          }
          break;
        case "Escape":
          this.isBlur = false;
          break;

      }

    },

    getImagePath(img) {
      return '/images/products/' + img.replace(/\.[^/.]+$/, '') + '.webp';
    },

    showHide(dir) {

      let show = true;

      if (dir === 'left') {
        if (this.getCurrIndex === 0) {
          show = false;
        }
      } else {
        if (this.getCurrIndex === this.getGalleryData.length - 1) {
          show = false;
        }
      }

      return (!show) ? 'hide-arrow' : '';

    },
    handleImgFullscreen(item, index) {


      this.isBlur = true;
      this.$nextTick(() => {
        this.selectedImg = item;
        this.currIndex = index;
        const currId = '#carousel' + this.currIndex;
        gsap.to(currId, {
          border: '4px solid #F48B41'
        });
      })



    },
    handleExitMode() {
      this.isBlur = false;
      this.selectedImg = null;
    },
    handleImageCarousel(img, index) {

      console.log(img, index, ' img index');

      const prevId = '#carousel' + this.currIndex;
      this.currIndex = index;
      this.selectedImg = img;
      const currId = '#carousel' + index;
      gsap.to(currId, {
        border: '4px solid #F48B41'
      });
      gsap.to(prevId, {
        border: '4px solid transparent'
      });
    },
    handleNextRightImg() {
      const prevId = '#carousel' + this.currIndex;
      this.currIndex = this.currIndex + 1;
      this.selectedImg = this.galleryData[this.currIndex];
      const currId = '#carousel' + this.currIndex;
      gsap.to(currId, {
        border: '4px solid #F48B41'
      });
      gsap.to(prevId, {
        border: '4px solid transparent'
      });
    },
    handleNextLeftImg() {
      const prevId = '#carousel' + this.currIndex;
      this.currIndex = this.currIndex - 1;
      this.selectedImg = this.galleryData[this.currIndex];
      const currId = '#carousel' + this.currIndex;
      gsap.to(currId, {
        border: '4px solid #F48B41'
      });
      gsap.to(prevId, {
        border: '4px solid transparent'
      });
    }
  }
};
</script>
<style scoped>


/**::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:active {
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #939393;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #818181;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #7E7E7E;
}*/


.hide-arrow {
  visibility: hidden !important;
}

.product-gallery-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem 0;
}

.product-gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-gallery-img-box {
  cursor: pointer;
  height: 120px;
  border: 3px solid #FFFFFF;
  display: flex;
}

.product-gallery-img-box:hover {
  border: 3px solid #F48B41;
}

.product-gallery-blur {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  background: rgb(49, 49, 49);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-gallery-fullscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-gallery-icon-box {
  display: flex;
  flex-direction: row;
/*  justify-content: center;*/
  min-width: 900px;
  margin: 0px auto;

}

.product-gallery-img-icon {
  width: 110px;
  height: 80px;
  object-fit: cover;
}

.product-gallery-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  height: 100%;

}

.product-gallery-left-arrow:hover,
.product-gallery-right-arrow:hover {
  fill: #FFFFFF !important;
  cursor: pointer;
}

.product-gallery-left-arrow {
  rotate: 180deg;
}

.product-gallery-exit {
  color: #FFFFFF;
  cursor: pointer;
}

.product-gallery-main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-gallery-main-img-box {

  width: 80%;
  max-width: 1200px;
  aspect-ratio: 3/2;
  overflow: hidden;
  background-color: #0D7BC6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-gallery-img-icon-card {
  border: transparent 4px solid;
  cursor: pointer;
  max-width: 120px;
  height: 88px;
}

.product-gallery-img-icon-card:hover {
  border: 4px solid #FFFFFF !important;
}

.product-gallery-header {
  width: 100%;
  height: 44px;
  min-height: 44px;
  background-color: #181818;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pgh-left {
  margin-left: 20px;
  color: gray;
}

.pgh-right {
  margin-right: 20px;
}

.product-gallery-footer {

  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #1c1c1c;
  box-shadow: 1px -4px 50px 20px rgba(0, 0, 0, .9);
  display: flex;
/*  justify-content: center;
  align-items: center;*/
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 115px;
}

.gallery-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;


  visibility: visible;
  user-select: none;
  cursor: pointer;
}

.arrow-left {
  margin-left: 10px;
}

.arrow-right {
  margin-right: 10px;
}

.arrow-size {
  width: 12px;
  height: 12px;
}

@media only screen and (max-height: 1000px) {

  .product-gallery-main-img-box {

    width: initial;
    height: 80%;
  }
}
</style>