<template>
  <div class="product-banner-outer">
    <div class="product-banner-responsive">
      <LocLink class="product-banner-link" :to="Props.bannerPath">
        {{ $rt(Props.bannerLink) }}
      </LocLink>
      <div class="product-banner-title" v-html="getHTML"></div>
    </div>
    <div class="product-banner-container">
      <div class="product-banner-text-box">
        <LocLink class="product-banner-link responsive" :to="Props.bannerPath">
          <div v-html="$rt(item.bannerLink).replace(/[™®©]/g, '<sup>$&</sup>').toUpperCase()"></div>
        </LocLink>
        <h1 class="product-banner-title responsive" v-html="getHTML"></h1>
        <div v-for="(para, index) in item.paragraphs" :key="index">
          <div class="product-banner-text" id="product-banner-text" v-html="$rt(para)"></div>
        </div>

        <BaseButton v-if="Props.isButton" :label="$rt(item.label)" :buttonStyle="bannerStyle" :url="$rt(item.url)" />

      </div>
      <div class="product-banner-image-box">
        <img class="product-banner-img" :src="'/images/products/' + $rt(item.resourceUri) + '-hero.webp'" />
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from '../base/BaseButton.vue';
import LocLink from '../base/LocLink.vue';
export default {
  components: {
    BaseButton, LocLink
  },
  data() {
    return {
      bannerStyle: {
        color: '#0089D0',
        hover: '#212121',
      },
      galleryStyle: {
        color: '#0089D0',
        hover: '#212121',
        fontSize: '13px'
        /*
  *   cursor: pointer;
color: white;
text-align: center;
font-size: 13px;
font-weight: 700;
letter-spacing: 0.05em;
width: 100%;
  * */
      }
    };
  },
  props: ['item',
    'bannerCategory',
    'isButton',
    'label',
    'bannerLink',
    'path',
  ],
  computed: {

    getHTML() {
      return this.$rt(this.item.title).replace(/[™®©]/g, '<sup>$&</sup>');
    },
    handleBackgroundBanner() {
      if (this.$props.item.resourceUri != null) {
        return {
          'background-image': 'url(' + this.$rt(this.$props.item.resourceUri) + ')',
        };
      } else {
        return '';
      }
    },
    title() {
      return this.Props.title;
    },
    // formatTitle() {
    //   if (this.Props.title.includes('®')) {
    //     return (
    //       this.Props.title.slice(0, -1) + "<span class='r'><sup>®</sup></span>"
    //     );
    //   } else {
    //     return this.Props.title;
    //   }
    // },
    Props() {
      return this.$props.item;
    },
    isEmpty() {
      let obj = this.$props.item.resourceUri;
      return Object.keys(obj).length === 0;
    },
  },
  methods: {
  }
};
</script>

<style>
.product-banner-text p {
  font-size: 20px;
  line-height: 1.5em;
}
</style>

<style scoped>
.product-banner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 10px;
}

.product-banner-outer {
  background-color: #F7F6F1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 50px;
}

.product-banner-link {
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  color: #3b3b3b;
  font-weight: bold;
}

.product-banner-title {
  font-weight: 900;
  font-size: 55px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.product-banner-text {
  font-size: 20px;
  max-width: 550px;
  line-height: 1.5;
}

.product-banner-img {
  object-fit: contain;
  max-width: 100%;
  max-height: 550px;
  padding: 0;
}

.product-banner-responsive {
  display: none;
}

@media only screen and (max-width: 600px) {
  .product-banner-outer {
    align-items: flex-start;
    padding: 50px 25px 80px;
  }

  .product-banner-title {
    margin-top: 20px;
    font-size: 30px;
  }

  .product-banner-container {
    flex-direction: column-reverse;
  }

  .product-banner-text {
    font-size: 15px;
  }

  .product-banner-responsive {
    display: initial;
  }

  .responsive {
    display: none;
  }
}
</style>
