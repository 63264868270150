<template>
  <div v-if="productTitle" class="product-features-container">
    <h4 class="product-title" :id="productTitle.mlVar">{{ productTitle.mltText.toUpperCase() }}</h4>
    <div class="features-html" v-html="$rt(items)"></div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      features: null,
    };
  },
  mounted() {
    //this.features = this.$props.items;
  },
  props: [
    'items',
    'productTitle'
  ],
  computed: {
  }
};
</script>

<style>
/* styles for v-html cannot be scoped */

.features-html h2 {
  font-size: 19px !important;
  font-weight: 700;
  padding: 20px 0px;
}
</style>

<style scoped>
.product-features-container {
  padding-bottom: 1rem;
}

.product-features-text {
  margin-top: 20px;
}

.product-features-subhead {
  font-size: 19px;
  margin-top: 30px;
}

.product-features-li {
  font-size: 15px;
}

.product-features-ul {
  margin-top: 15px;
}

.features-li {
  font-size: 15px;
}

.features-ul {
  margin-top: 15px;
}
</style>
