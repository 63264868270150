<template>
  <div id="product-nav" class="product-nav-container" v-if="titleKey">
    <div class="product-content-nav" v-for="item, index in titleKey" :key="index"
      @click="this.handleLink($rt(item.mlVar))">
      <p class="product-content-nav-text">{{ $rt(item.mltText) }}</p>
      <div class="product-title-chevron">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
          <title>chevron-right</title>
          <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </svg>
      </div>
    </div>
    <div class="product-nav-button-container">
      <nuxt-link class="product-nav-button" to="/dealers">Find A Dealer</nuxt-link>
    </div>
  </div>
</template>
<script>

export default {
  props: [
    'page'
  ],
  computed: {
    titleKey() {
      return this.$props.page;
    }
  },
  methods: {
   
    handleLink(link) {
      this.scrollToElement(link);
    },
    scrollToElement(link) {

      console.log(link, ' link');

      const element = document.getElementById(link);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }
  }
};

</script>
<style scoped>
.product-nav-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 0 0px;
  height: 100vh;
  width: 100%;
  flex: 1;
  position: sticky !important;
  top: 0px;
}

@media only screen and (max-width: 900px) {

  .product-nav-container {
    display: none;
  }

}

@media only screen and (max-width: 650px) {}


.product-nav-button {
  cursor: pointer;
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.05em;
  width: 100%;
}

.product-nav-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0089D0;
  margin-top: 1rem;
  width: 100%;
  height: 45px;

}

.product-content-nav {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  color: #3B3B3B;
  text-decoration: none;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px;
}

.product-title-chevron {
  opacity: .3;
}

.product-content-nav:hover .product-title-chevron {
  opacity: 1;
}

.product-content-nav-text {
  color: #3B3B3B;
  font-size: 15px;
}
</style>