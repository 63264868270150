<template>
  <div class="pr-holder">
    <LocLink class="product-related-box" @mouseover="this.handleHoverArrow(product)"
               @mouseleave="this.handleLeaveArrow(product)" :to="'/products/' + familyURL + '/' + $rt(product.productURL)">
      <div class="product-related-image-box">
        <img class="product-related-image"
             :src="'/images/products/' + $rt(product.productURL) + '-hero.webp'" />
      </div>
      <div class="product-related-content">
        <h4 class="product-related-item-title">{{ $rt(product.productName) }}</h4>
        <div class="product-related-summary" v-html="$rt(product.productSummary)"></div>
        <BasePerformance :performanceData="getPerfData(product)" size="small" />
      </div>
      <div class="product-related-view-container" @mouseover="handleHoverArrow" @mouseleave="handleLeaveArrow">
        <div class="pr-arrow-holder">{{ this.toUpperCase($t('viewProduct')) }}
            <BaseArrow ref="arrow" color="rgb(147, 147, 147)" hoverColor="#FFA500" class="ann-arrow-pos"/>
          </div>
      </div>
    </LocLink>
  </div>
</template>

<script>

import BaseArrow from "~/components/base/BaseArrow.vue";
import {usePerformanceStore} from "~/store/PerformanceStore";
import BasePerformance from "~/components/base/BasePerformance.vue";
import LocLink from "~/components/base/LocLink.vue";

export default {
  props: [
  'product'
  ],
  components: {
    BaseArrow,
    BasePerformance,
    LocLink
  },
  data () {
    return {

    };
  },
  mounted () {

  },
  computed: {
    familyURL() {      
      var family = this.$tm('productFamilies').find((fam)=>{ return fam.pfID === this.product.pfID_fk });
      if(family)
        return this.$rt(family.productFamilyURL);
      else return '';
    }
  },
  methods: {
    async getPerfData(product) {
      const performanceStore = usePerformanceStore();
      const perfData = await performanceStore.getAllPerformanceData(product.pID);
      return perfData;
    },
    handleHoverArrow() {
      this.$refs.arrow.handleOver();
      //this.stopSlideShow();
    },
    handleLeaveArrow() {
      this.$refs.arrow.handleOut();
    },
    toUpperCase(string) {
      return string.toLowerCase().split(' ').map(function (word) {
        return word[0].toUpperCase() + word.substr(1);
      }).join(' ');
    },
  }
};
</script>

<style scoped>

.icon-type {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 15px;
  align-items: center;

}

.product-related-spec {
  font-size: 15px;
  margin: 3px 3px;
}

.product-related-chevron {
  fill: black;
}

.product-related-spec-icon {
  width: 14px;
  height: 14px;
  margin-bottom: 3px;
  margin-right: 5px;
}

.product-related-box:visited,
.product-related-box:link {
  color: black;
}

.product-related-box {
  border: 1px solid #E7E7E7;
  text-decoration: none;
  color: black;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.product-related-image-box {
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.product-related-image {
  max-width: 100%;
  max-height: 100%;
  min-height: 150px;
  object-fit: contain;
}

.product-related-content {
  font-size: 1.35rem;
  border-top: 1px solid #c2c2c2;
  margin-right: 25px;
  margin-left: 25px;
  grid-row: 2/3;
  padding-bottom: 20px;
}

.product-related-list {
  padding: 0px;
  margin: 5px 0;
  list-style-type: none;
}

.product-related-view-container {
  display: flex;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
}

.pr-arrow-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border-top: 1px #c2c2c2 solid;
}

.product-related-view-box {
  border-top: 1px #c2c2c2 solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 13px;
}

.product-related-arrow {
  fill: #F48B41;
}

.product-related-summary {
  font-weight: normal;
  line-height: 1.4em;
  font-size: 13px;
  margin-bottom: 10px;
}

.product-related-item-title {
  font-size: 15px;
  line-height: 1.3em;
  margin-top: 25px;
  margin-bottom: 25px;

}

.pr-holder {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

@media only screen and (max-width: 1100px) {

}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 500px) {

}



</style>
