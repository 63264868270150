<template>
  <div>
    <h4 v-if="productTitle" class="product-title" :id="productTitle.mlVar">{{ productTitle.mltText.toUpperCase() }}</h4>
    <div class="product-related-container">
      <div class="product-related-title-container">
        <div class="product-related-title-box" v-for="(item, index) in products" :key="index"
          @click="handleProductDisplay(index)">
          <div class="product-related-title" v-html="$rt(item.productName).replace(/[™®©]/g, '<sup>$&</sup>')"></div>
          <div class="product-related-title-chevron">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
              <title>chevron-right</title>
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="product-related-layout">
        <ProductsRelatedCard v-for="(product, index) in products" :product="product" v-show="selectedIndex === index"/>
      </div>
    </div>
  </div>
</template>
<script>
import { usePillarStore } from '~/store/PillarStore';
import ProductsRelatedCard from "~/components/products/related/ProductsRelatedCard.vue";
export default {
  setup() {
    const pillarStore = usePillarStore();
    return {
      pillarStore
    };
  },
  props: [
    'item',
    'productTitle'
  ],
  components : {
    ProductsRelatedCard
  },
  data() {
    return {
      items: [],
      pageProducts: [],
      selectedIndex: 0
    };
  },
  mounted() {
    this.items = this.$props.item;
    this.pageProducts = this.$props.item;

  },
  computed: {
    resources() {
      return this.items;
    },
    products() {
      return this.pageProducts;
    }
  },
  methods: {

    toUpperCase(string) {
      return string.toLowerCase().split(' ').map(function (word) {
        return word[0].toUpperCase() + word.substr(1);
      }).join(' ');
    },

    handleProductDisplay(index) {

      if(window.innerWidth < 600) {

        let r = useRoute();
        let famUrl = r.path.split('/');
        famUrl.pop();
        let url = famUrl[famUrl.length - 1];
        useRouter().push('/products/' + url);
      } else {
        this.selectedIndex = index;
      }
    },

  }
};

</script>

<style>

/* DO NOT REMOVE - FOR USE WITH V-HTML */

.product-related-title {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>

<style scoped>
.product-related-layout {
  flex: 1;
  padding: 20px;
}

.product-related-container {
  display: flex;
  flex-direction: row;
  background-color: #F7F6F1;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.product-related-title-container {
  flex: 1.5;
  padding: 20px 0px 20px 20px;
}

.product-related-title-box {
  cursor: pointer;
  display: flex;
  background: #fff;
  padding: 13px;
  font-weight: 700;
  border: 1px solid #e7e7e7;
  margin-bottom: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-related-title-box:hover  {
  text-decoration: underline;
}

.product-related-title-box:hover {
  fill: black;
}

.product-related-title-chevron {
  fill: black;
  transform: translateY(2px) scale(1.1);
}

.product-related-title-chevron {
  fill: #D3CDC2;
}

@media only screen and (max-width: 900px) {

   .product-related-layout {
    flex: 1;
  }

  .product-related-title-container {
    flex: 1;
  }
}

@media only screen and (max-width: 600px) {

  .product-related-layout {
    display: none;
  }

  .product-related-title-container {
    margin-right: 20px;
  }

}
</style>