
<template>
  <div v-if="getItems" class="story-banner-summary-container">
    <h1>{{ getItems.title }}</h1>
    <img :src="$rt(getItems.imageUri)" >
    <p class="story-text" v-for="element, index in getItems.paragraphs" :key="index">{{ element }}</p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      resources: null
    };
  },
  props: [
    'items'
  ],
  mounted () {
    this.resources = this.$props.items;
  },
  computed: {
    getItems () {
      return this.resources;
    }
  }
};
</script>
<style scoped>
.story-banner-summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.story-text {
  font-size: 20px !important;
  width: 80% !important;
  margin-top: 20px;
  margin-bottom: 10px;
}


</style>